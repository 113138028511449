<template>
  <div class="tabs-layout">
    <dynamic-tabs :activeName.sync="activeName" :tabs="tabs"></dynamic-tabs>
  </div>
</template>

<script>
import DynamicTabs from '../../../components/DynamicTabs.vue';

export default {
  name: '',
  components: {
    DynamicTabs,
  },
  data() {
    return {
      activeName: 'wait-redeem',
      tabs: [
        {
          label: '寄存期满待赎回',
          component: 'wait-redeem',
        },
        {
          label: '已赎回待回寄',
          component: 'redeemed-wait-return',
        },
        {
          label: '已回寄待确认到货（赎回）',
          component: 'wait-confirm-arrival',
        },
        {
          label: '已确认到货（赎回）',
          component: 'confirmed-arrival',
        },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
